<template>
  <div class="tabbed round">
    <ul id="period-tab">
      <li
        v-for="(period, n) in this.kwTargets.kw_targets"
        :key="n"
        :class="{ active: period.target_no === currentPeriodInQuery }"
        style="font-family:'Noto Sans KR', sans-serif;background-color:#e0e0e0; font-family: 'Nanum Gothic', sans-serif; box-sizing: border-box; display: inline-block; font-size: 18px; padding:30px 60px; font-weight: bold; width: auto; color: #3c4a64"
        v-on:click="clickEvent(period.target_no)"
      >
        <span v-if="period.target_no === currentPeriodInQuery">최근 </span>{{ period.target_representative_name }} <span v-if="period.target_no === currentPeriodInQuery">의 주요 키워드</span>
      </li>
    </ul>
  </div>
</template>

<script>
import EventBus from "../../../EventBus";
import {kwTargets} from "../../../mixins/explore_period/kwTargets";

export default {
  name: "PeriodTab",
  mixins: [kwTargets],
  data() {
    return {
      periodTab: null,
      currentPeriodInQuery: this.$route.query.period
    };
  },
  created() {
  },
  methods: {
    clickEvent: function(selectedPeriodTargetNo) {
      EventBus.$emit("reloadExplorePeriodTable", selectedPeriodTargetNo);
      this.$router.push({
        query: {
          dataSet: this.$store.getters.getSelectedDataSet,
          period: selectedPeriodTargetNo
        }
      });

    },
    setSelectedPeriod: function(period) {
      this.currentPeriodInQuery = period;
    }
  },
  watch: {
    $route(to, from) {
      this.setSelectedPeriod(this.$route.query.period);
    }
  }
};
</script>
<style scoped>
/* Tabbed Styles */
.tabbed {
  min-width: 400px;
  margin: 0 auto;
  border-bottom: 4px solid #3c4a64;
  overflow: hidden;
  transition: border 250ms ease;
}
.tabbed ul {
  margin: 0px;
  padding: 0px;
  overflow: hidden;
  list-style-type: none;
}
.tabbed ul * {
  margin: 0px;
  padding: 0px;
}
.tabbed ul li {
  display: block;
  float: left;
  padding: 10px 24px 8px;
  background-color: #fff;
  margin-right: 5px;
  z-index: 2;
  position: relative;
  cursor: pointer;
  color: #777;

  text-transform: uppercase;
  font: 600 13px/20px roboto, "Open Sans", Helvetica, sans-serif;

  transition: all 250ms ease;
}

.tabbed ul li.active {
  z-index: 3;
}
.tabbed ul li.active,
.tabbed ul li.active:before,
.tabbed ul li.active:after {
  background-color: #3c4a64;
  color: #fff;
}

/* Round Tabs */
.tabbed.round ul li {
  /*border-radius: 8px 8px 0 0;*/
}
.tabbed.round ul li:before {
  border-radius: 0 8px 0 0;
}
.tabbed.round ul li:after {
  border-radius: 8px 0 0 0;
}

a.iprodev {
  line-height: normal;
  font-family: Varela Round, sans-serif;
  font-weight: 600;
  text-decoration: none;
  font-size: 13px;
  color: #a7aaae;
  position: fixed;
  left: 20px;
  bottom: 20px;
  border: 1px solid #a7aaae;
  padding: 12px 20px 10px;
  border-radius: 50px;
  transition: all 0.1s ease-in-out;
  text-transform: uppercase;
}
a.iprodev:hover {
  background: #ffffff;
}
.active {
  color: white !important;
  background-color: #3c4a64 !important;
}
</style>
