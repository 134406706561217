export const kwTargets = {
    data: function () {
        return {
            kwTargets: {
                "date_stat_params"	: {
                    "base_date"			: "2020-06-11",
                    "base_start_date"	: "2014-01-01"
                },
                "kw_targets"	:
                    [
                        {
                            "target_no"		: "0",
                            "target_name"	: "recent_1week",
                            "target_representative_name" : "1주",
                            "score_tune_1"	: "1",
                            "target_condition" : [
                                {
                                    "doc_field"	: "date",
                                    "start_date" : "2020-06-05"
                                }
                            ],
                            "base_target_condition" : [
                                {
                                    "doc_field"	: "date",
                                    "start_date" : "2020-05-29"
                                }
                            ]
                        },
                        {
                            "target_no"		: "1",
                            "target_name"	: "recent_1_month",
                            "target_representative_name" : "1달",
                            "score_tune_1"	: "1",
                            "target_condition" : [
                                {
                                    "doc_field"	: "date",
                                    "start_date" : "2020-05-12"
                                }
                            ],
                            "base_target_condition" : [
                                {
                                    "doc_field"	: "date",
                                    "start_date" : "2020-04-12"
                                }
                            ]
                        },
                        {
                            "target_no"		: "2",
                            "target_name"	: "recent_3month",
                            "target_representative_name" : "3달",
                            "score_tune_1"	: "1",
                            "target_condition" : [
                                {
                                    "doc_field"	: "date",
                                    "start_date" : "2020-03-11"
                                }
                            ],
                            "base_target_condition" : [
                                {
                                    "doc_field"	: "date",
                                    "start_date" : "2019-12-12"
                                }
                            ]
                        },
                        {
                            "target_no"		: "3",
                            "target_name"	: "recent_1year",
                            "target_representative_name" : "1년",
                            "score_tune_1"	: "2",
                            "target_condition" : [
                                {
                                    "doc_field"	: "date",
                                    "start_date" : "2019-06-12"
                                }
                            ],
                            "base_target_condition" : [
                                {
                                    "doc_field"	: "date",
                                    "start_date" : "2018-06-12"
                                }
                            ]
                        }
                    ]
            }
        }
    }
}