<template>
  <v-container style="max-width: none; margin:0">
    <v-row>
      <v-col cols="12" style="">
        <OntologySectionTab></OntologySectionTab>
        <PeriodTable></PeriodTable>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PeriodTable from "../../../components/explore_period/first_page/PeriodTable";
import OntologySectionTab from "../../../components/explore_period/first_page/PeriodTab";
import axios from "axios";
import store from "@/store/index.ts";

export default {
  name: "PeriodTablePage",
  components: {
    PeriodTable,
    OntologySectionTab
  },
  async beforeRouteEnter(to, from, next) {
    next();
  },
  mounted() {
    if (!('dataSet' in this.$route.query)){
      this.$router.push({
        query : {
          dataSet :this.$store.getters.getSelectedDataSet,
          period : '0',
        }
      })
    }
  }
};
</script>

<style scoped></style>
